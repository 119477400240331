import React from "react";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import * as dateService from "../../../../services/date.service";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DraftSubmissionDetails from "../Students/DraftSubmissionDetails";
import TotalTime from "../Students/TotalTime";
import VisibilityIcon from "@mui/icons-material/Visibility";

import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useDispatch } from "react-redux";

import { tooltips } from "../constants";
import DangerousIcon from "@mui/icons-material/Dangerous";
import {
  updateAssignmentStopAction,
  updateAssignmentVisibilityAction,
} from "../../../../store/ClassDetails/actions";

export default function AssignmentRow(props: any) {
  const dispatch = useDispatch();
  // TODO add interface
  const [open, setOpen] = React.useState(false);
  const { assignment } = props;
  const theme = useTheme();
  const dangerColor = theme.palette.error.light;

  const submissionByTime: any[] = props.assignment.studentSubmissions
    .filter((c: any) => c.submissionDetails.totalTime > 0)
    .sort(
      (a: any, b: any) =>
        b.submissionDetails.totalTime - a.submissionDetails.totalTime
    );
  const getRank = (id: string): string => {
    const index = submissionByTime.findIndex((c) => c.id === id);
    if (index >= 0) {
      return `${index + 1}/${submissionByTime.length}`;
    }
    return "-";
  };

  const isPeerReview = (): boolean => {
    try {
      const dlConst = assignment.draftSubmissions.some(
        (c: any) => c.draftPeerReviewDeadline
      );
      const stdConst = assignment.peerReview.some(
        (c: any) => c.reviewers.length > 0
      );
      return dlConst && stdConst;
    } catch {
      return false;
    }
  };

  const toggleVisiblity = async () => {
    dispatch(
      // @ts-ignore
      updateAssignmentVisibilityAction(assignment.id, !assignment.visible)
    );
  };
  const toggleStop = async () => {
    dispatch(
      // @ts-ignore
      updateAssignmentStopAction(assignment.id, !assignment.stoped)
    );
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{assignment.title}</TableCell>
        <TableCell align="left">
          {dateService.toCustomTimeZone(
            props.classTimeZone,
            assignment.startDate
          )}
        </TableCell>
        <TableCell align="left">
          {" "}
          {dateService.toCustomTimeZone(
            props.classTimeZone,
            assignment.endDate
          )}
        </TableCell>
        <TableCell align="left">
          {assignment.autoSubmission ? "Yes" : "No"}
        </TableCell>
        
        <TableCell align="right">
          {/* <IconButton
            onClick={(e) => props.showDesc(assignment.id)}
            aria-label="expand row"
            size="small"
          >
            <InfoOutlinedIcon />
          </IconButton> */}
          <Tooltip title={tooltips.MODIFY} placement="bottom">
            <IconButton
              onClick={(e) => props.editAssignment(assignment)}
              aria-label="expand row"
              size="small"
            >
              <ModeEditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={assignment.stoped ? tooltips.PAUSE : tooltips.PLAY}
            placement="bottom"
          >
            <IconButton
              onClick={toggleStop}
              aria-label="expand row"
              size="small"
            >
              {assignment.stoped ? (
                <DangerousIcon color="error" />
              ) : (
                <DangerousIcon />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title={tooltips.VISIBLE} placement="bottom">
            <IconButton
              onClick={toggleVisiblity}
              aria-label="expand row"
              size="small"
            >
              {assignment.visible ? (
                <VisibilityIcon color="success" />
              ) : (
                <VisibilityOffIcon />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title={tooltips.PEER_REVIEW} placement="bottom">
            <IconButton
              aria-label="Config peer review"
              size="small"
              onClick={() => props.openPeerReview(assignment.id)}
              color={isPeerReview() ? "success" : "default"}
            >
              <SwapHorizontalCircleIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={tooltips.DELETE} placement="bottom">
            <IconButton
              onClick={(e) => props.deleteAssignment(assignment)}
              aria-label="expand row"
              size="small"
            >
              <CloseIcon
                sx={{
                  color: "red",
                }}
              />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Students
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Student Name</TableCell>
                    <TableCell>Create date</TableCell>
                    <TableCell align="left">Last status change</TableCell>
                    <TableCell align="right">Status</TableCell>
                    <TableCell align="right">Total Time</TableCell>
                    <TableCell align="right">Rank</TableCell>
                    <TableCell align="right">Grade</TableCell>

                    <TableCell align="right">Submissions</TableCell>

                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.assignment.studentSubmissions.map((student: any) => {
                    return (
                      <>
                        <TableRow key={student.id}>
                          <TableCell component="th" scope="row">
                            {student.lastName},{" "}
                            <Typography
                              component="span"
                              variant="body2"
                              sx={{ textTransform: "capitalize" }}
                            >
                              {student.firstName.toLowerCase()}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            {dateService.toCustomTimeZone(
                              props.classTimeZone,
                              student.submissionDetails.createDate
                            )}
                          </TableCell>
                          <TableCell
                            sx={{
                              backgroundColor: student.submissionDetails.late
                                ? dangerColor
                                : "inherit",
                            }}
                          >
                            {dateService.toCustomTimeZone(
                              props.classTimeZone,
                              student.submissionDetails.lastChangeDate
                            )}
                          </TableCell>
                          <TableCell
                            sx={{
                              backgroundColor: student.submissionDetails.late
                                ? dangerColor
                                : "inherit",
                            }}
                            align="right"
                          >
                            {student.submissionDetails.status}
                          </TableCell>
                          <TableCell align="right">
                            <TotalTime
                              totalTime={student.submissionDetails.totalTime}
                            />
                          </TableCell>
                          <TableCell align="right">
                            {getRank(student.id)}
                          </TableCell>
                          <TableCell align="right">
                            {student.submissionDetails.score === -1
                              ? "-"
                              : student.submissionDetails.score}
                          </TableCell>

                          <TableCell>
                            <DraftSubmissionDetails
                              drafts={
                                student.submissionDetails.draftSubmissions
                              }
                              timezone={props.classTimeZone}
                            />
                          </TableCell>

                          <TableCell>
                            <Button
                              fullWidth
                              variant="contained"
                              href={
                                "/readassignment/" +
                                student.submissionDetails.submissionId
                              }
                              target="_blank"
                            >
                              Read
                            </Button>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
