import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import authHeader from "./auth-header";
import {
  Class,
  Classes,
  Course,
  RegisterClass,
  SubmissionStatus,
  TextDiff,
  WordCountChange,
} from "./models/classes.model";
import { AssignmentDetail } from "../containers/student/Assignment/model";

const HOST = process.env.REACT_APP_API_URL;
const ANALYZE_SUBMISSION = "api/classes/wordchangebyessayTime";
const CLASSES = "api/classes";
const SUBMISSION = "api/submission";
const CLASS_LIST = "classList";
const MY_CLASSES = "myclasses";
const CLASS_SEARCH = "ClassSearch";
const CLASS_ENROLL = "classenroll";

const GET_SUBMISSION_ID = "getnetsubmissionId";
const GET_ALL_CLASSES = "getlist";
const REGISTER_CLASS = "registerclass";
const TEXT_DIFF = "textdiff";
const GET_MY_CLASS = "getmyclass";
const GET_MY_ASSIGNMENT = "myassignment";
const NEW_DOCUMENT = "newdocumet";
const GET_DOCUMENT = "getdocument";
const DRAFT_SUBMISSION = "setDratSubmission";
const FINAL_SUBMISSION = "setFinalSubmission";
const GET_HEATMAP_LOGS = "getHeatmapLogs";
const GET_CHANGDIST_GRAPH = "getChangeDist";
const GET_CHANGDIST_LOGS = "getChangeDistJson";
const FEEDBACK = "feedback";
const ARCHIVE = "archive"

export const analyzeSubmission = async (submissionId: string) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${ANALYZE_SUBMISSION}`,
    params: { submissionId },
    data: {},
  };
  try {
    const apiCall: AxiosResponse<WordCountChange[]> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const getClasses = async (index:number) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${CLASSES}/${CLASS_LIST}`,
    data: {},
    params:{index}
  };
  try {
    const apiCall: AxiosResponse<Class[]> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const getMyClasses = async () => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${CLASSES}/${MY_CLASSES}`,
    data: {},
  };
  try {
    const apiCall: AxiosResponse<Class[]> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const searchClass = async (enrollkey: string) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${CLASSES}/${CLASS_SEARCH}`,
    data: {},
    params: { enrollkey },
  };
  try {
    const apiCall: AxiosResponse<Class> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const enrollClass = async (classId: string) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${CLASSES}/${CLASS_ENROLL}`,
    data: {},
    params: { id: classId },
  };
  try {
    const apiCall: AxiosResponse<Class> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const getNetSubmissionId = async (
  classId: string,
  studentIndex: number,
  assignmentId: string,
  direction: number
) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${CLASSES}/${GET_SUBMISSION_ID}`,
    data: { classId, studentIndex, assignmentId, direction },
  };
  try {
    const apiCall: AxiosResponse<String> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const getAllClassList = async (type: string) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${CLASSES}/${GET_ALL_CLASSES}`,
    data: {},
    params: { type },
  };
  try {
    const apiCall: AxiosResponse<Classes[]> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const registerClass = async (payload: RegisterClass) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${CLASSES}/${REGISTER_CLASS}`,
    data: { ...payload },
  };
  try {
    const apiCall: AxiosResponse<String> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const showTextDiff = async (oldId: string, newId: string) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${CLASSES}/${TEXT_DIFF}`,
    data: {},
    params: { oldId, newId },
  };
  try {
    const apiCall: AxiosResponse<TextDiff> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const getStudentClass = async (classId: string) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${CLASSES}/${GET_MY_CLASS}`,
    data: {},
    params: { id: classId },
  };
  try {
    const apiCall: AxiosResponse<Course> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const getStudentAssignment = async (assignmentId: string) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${CLASSES}/${GET_MY_ASSIGNMENT}`,
    data: {},
    params: { id: assignmentId },
  };
  try {
    // TODO add interface
    const apiCall: AxiosResponse<AssignmentDetail> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const newDocument = async (assignmentId: string) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${CLASSES}/${NEW_DOCUMENT}`,
    data: {},
    params: { id: assignmentId },
  };
  try {
    // TODO add interface
    const apiCall: AxiosResponse<any> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const getDocument = async (assignmentId: string) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${CLASSES}/${GET_DOCUMENT}`,
    data: {},
    params: { id: assignmentId },
  };
  try {
    // TODO add interface
    const apiCall: AxiosResponse<any> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const handleSubmission = async (
  fieldId: string,
  submissionType: string
) => {
  const endpoint =
    submissionType === "draft" ? DRAFT_SUBMISSION : FINAL_SUBMISSION;
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${CLASSES}/${endpoint}`,
    data: {},
    params: { id: fieldId },
  };
  try {
    // TODO add interface
    const apiCall: AxiosResponse<SubmissionStatus> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const getHeatMapLogs = async (subId: string) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${CLASSES}/${GET_HEATMAP_LOGS}`,
    data: {},
    params: { id: subId },
  };
  try {
    // TODO add interface
    const apiCall: AxiosResponse<string> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const getChangeDistGraph = async (
  submissionId: string
): Promise<string> => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${CLASSES}/${GET_CHANGDIST_GRAPH}`,
    params: { submissionId },
    data: {},
  };
  try {
    const apiCall: AxiosResponse<string> = await axios(axiosConfig);
    return apiCall.data;
  } catch (err) {
    throw err;
  }
};

export const getChangeDistlogs = async (submissionId: string) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${CLASSES}/${GET_CHANGDIST_LOGS}`,
    params: { submissionId },
    data: {},
  };
  try {
    const apiCall: AxiosResponse<any> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const setFeedbackRead = async (submissionId: string) => {
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "PUT",
    url: `${HOST}/${SUBMISSION}/${FEEDBACK}`,
    params: { submissionId },
    data: {},
  };
  try {
    const apiCall: AxiosResponse<any> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
};

export const archiveClass = async(id:string ) =>{
  const axiosConfig: AxiosRequestConfig = {
    headers: authHeader(),
    method: "POST",
    url: `${HOST}/${CLASSES}/${ARCHIVE}`,
    params: { id },
    data: {},
  };
  try {
    const apiCall: AxiosResponse<any> = await axios(axiosConfig);
    return apiCall;
  } catch (err) {
    throw err;
  }
}
