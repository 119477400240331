import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography,
  Button,
  TableFooter,
  IconButton,
  Tooltip,
} from "@mui/material";
import Main from "../../../elements/public/main";
import { Link } from "react-router-dom";
import { validate } from "../../../actions/auth";
import { connect } from "react-redux";
import { Class } from "../../../services/models/classes.model";
import { archiveClass, getClasses } from "../../../services/classes.service";
import ArchiveIcon from "@mui/icons-material/Archive";
import ArchiveNotification from "./ArchiveNotification";

class ClassIndex extends React.Component<any, any> {
  // TODO add interface
  constructor(props: any) {
    super(props);
    this.formatDate = this.formatDate.bind(this);
    this.handleLoadMore = this.handleLoadMore.bind(this);
    this.archive = this.archive.bind(this);
    this.closeArchiveNotification = this.closeArchiveNotification.bind(this);
    this.openArchiveNotification = this.openArchiveNotification.bind(this);
    this.state = {
      classList: [],
      dateformat: "yyyy-MM-dd",
      index: 0,
      lastIndex: false,
      archiveId: null,
    };
  }
  async componentDidMount() {
    this.props.dispatch(validate());
    this.handleLoadMore();
  }

  async handleLoadMore() {
    try {
      const classes = await getClasses(this.state.index);
      const currentClasses = this.state.classList;
      const loadedClasses = classes.data;
      if (loadedClasses.length === 0) {
        this.setState({
          lastIndex: true,
        });
        return;
      }
      this.setState({
        classList: [...currentClasses, ...loadedClasses],
        index: this.state.index + 1,
      });
    } catch (err) {}
  }

  async archive(id: string) {
    try {
      const response = await archiveClass(id);
      if (response.status === 200) {
        this.setState({
          index: this.state.index + 1,
          classList: this.state.classList.filter((c: any) => c.id !== id),
          archiveId: null,
        });
      }
    } catch (e) {}
  }
  closeArchiveNotification() {
    this.setState({ archiveId: null });
  }

  openArchiveNotification(id: string) {
    this.setState({ archiveId: id });
  }

  formatDate(date: string) {
    return date.substring(0, 10);
  }

  render() {
    const { classList } = this.state;

    return (
      <Main>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={9} lg={10} my={1}>
            <Typography variant="h3">Instructor Class List</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2} my={1}>
            <Button variant="contained" href="/RegisterClass" fullWidth>
              New Class
            </Button>
          </Grid>
        </Grid>

        {classList && (
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Class Name</TableCell>
                  <TableCell align="left">Enrollment Key</TableCell>
                  <TableCell align="left">Subject Area</TableCell>
                  <TableCell align="left">Student Level</TableCell>
                  <TableCell align="left">Start Date</TableCell>
                  <TableCell align="left">End Date</TableCell>
                  <TableCell align="left"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {classList.map((row: Class) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">
                      <Link to={"/classdetail/" + row.id}>{row.className}</Link>
                    </TableCell>
                    <TableCell align="left">{row.enrollkey}</TableCell>
                    <TableCell align="left">{row.subjectArea}</TableCell>
                    <TableCell align="left">{row.studentLevel}</TableCell>
                    <TableCell align="left">
                      {this.formatDate(row.startDate)}
                    </TableCell>
                    <TableCell align="left">
                      {this.formatDate(row.endDate)}
                    </TableCell>
                    <TableCell align="left">
                      <Tooltip title="Archive" placement="left">
                        <IconButton
                          onClick={() => this.openArchiveNotification(row.id)}
                        >
                          <ArchiveIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              {this.state.classList.length > 0 && !this.state.lastIndex && (
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Button fullWidth onClick={this.handleLoadMore}>
                        Load More
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </TableContainer>
        )}
        <ArchiveNotification
          archiveId={this.state.archiveId}
          handleClose={this.closeArchiveNotification}
          handleArchive={this.archive}
        />
      </Main>
    );
  }
}

export default connect()(ClassIndex);
