import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  AppBar,
  Box,
  Dialog,
  IconButton,
  Tab,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import ReviewerTab from "./Reviewer";
import DeadlineTab from "./Deadline";
import { initUsavedChange, TabType, UsavedChange } from "./model";
import Reviews from "./Reviews";
import Notification from "./Notification";

interface PeerReviewDialogProps {
  open: boolean;
  assignmentId: string;
  handleClose: () => void;
}

const PeerReviewDashboard: React.FC<PeerReviewDialogProps> = ({
  open,
  assignmentId,
  handleClose,
}) => {
  const [tab, setTab] = React.useState<TabType>("Reviewer");
  const handleTab = (event: React.SyntheticEvent, newValue: TabType) => {
    setTab(newValue);
  };

  const [unsaved, setUnsaved] = useState<UsavedChange>(initUsavedChange);
  const handleUnsaved = (state: string, status: boolean) => {
    setUnsaved({ ...unsaved, [state]: status });
  };

  const close = () => {
    if (!unsaved.deadline && !unsaved.reviewer) {
      handleClose();
    } else {
      setUnsaved({ ...unsaved, notification: true });
    }
  };

  const handleDismiss = () =>{
    handleClose();
    setUnsaved(initUsavedChange);
  }
  return (
    <Dialog fullScreen open={open} onClose={close}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={close}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            PeerReview
          </Typography>
        </Toolbar>
      </AppBar>
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleTab} aria-label="Reviewer Tabs" centered>
            <Tab label="Reviewers" value="Reviewer" />
            <Tab label="Deadlines" value="Deadlines" />
            <Tab label="Reviews" value="Reviews" />
          </TabList>
        </Box>
        <TabPanel value="Reviewer">
          <ReviewerTab
            assignmentId={assignmentId}
            open={open}
            setTab={setTab}
            setUnsaved={handleUnsaved}
          />
        </TabPanel>
        <TabPanel value="Deadlines">
          <DeadlineTab
            assignmentId={assignmentId}
            open={open}
            setUnsaved={handleUnsaved}
          />
        </TabPanel>
        <TabPanel value="Reviews">
          <Reviews
            assignmentId={assignmentId}
            open={open}
            setUnsaved={handleUnsaved}
          />
        </TabPanel>
      </TabContext>
      <Notification
        open={unsaved.notification}
        handleDismiss={handleDismiss}
        handleClose={(state: boolean) =>
          setUnsaved({ ...unsaved, notification: state })
        }
      />
    </Dialog>
  );
};

export default PeerReviewDashboard;
