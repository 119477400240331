import React from "react";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import * as dateService from "../../../../services/date.service";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import { Submission } from "../../../../store/ClassDetails/model";
import DraftSubmissionDetails from "./DraftSubmissionDetails";
import TotalTime from "./TotalTime";

export default function StudentRow(props: any) {
  // TODO add interface
  const [open, setOpen] = React.useState(false);
  const { row,rank } = props;
  const theme = useTheme();
  const dangerColor = theme.palette.error.light;

  function getAssignmentTitle(assignmentId: string) {
    const assignments = props.assignments;
    const index = assignments.findIndex((c: any) => c.id === assignmentId); // TODO add interface
    try {
      return assignments[index].title;
    } catch (e) {
      return "unknown";
    }
  }

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          {row.lastName},{" "}
          <Typography
            component="span"
            variant="body2"
            sx={{ textTransform: "capitalize" }}
          >
            {row.firstName.toLowerCase()}
          </Typography>
        </TableCell>
        <TableCell align="right">{row.email}</TableCell>
        <TableCell>
          <TotalTime totalTime={row.totalTime} />
        </TableCell>
        <TableCell>{rank}</TableCell>
        
        <TableCell align="right">
          <IconButton
            onClick={(e) => props.deleteStudent(row)}
            aria-label="expand row"
            size="small"
          >
            <CloseIcon
              sx={{
                color: "red",
              }}
            />
          </IconButton>
        </TableCell>
        {/* <TableCell align="right">{row.totalSubmission}</TableCell>
        <TableCell align="right">{row.totalScore}</TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Assignments
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Assignment</TableCell>
                    <TableCell>Create date</TableCell>
                    <TableCell align="left">Last status change</TableCell>
                    <TableCell align="right">Status</TableCell>
                    {/* <TableCell align="right">Final?</TableCell> */}
                    <TableCell align="right">Grade</TableCell>
                    <TableCell align="left">Submissions</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* TODO add interface */}
                  {row.submissions.map((submission: Submission) => (
                    <TableRow key={submission.submissionId}>
                      <TableCell component="th" scope="row">
                        {getAssignmentTitle(submission.assignmentId)}
                      </TableCell>
                      <TableCell>
                        {dateService.toCustomTimeZone(
                          props.classTimeZone,
                          submission.createDate.toString()
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: submission.late
                            ? dangerColor
                            : "inherit",
                        }}
                      >
                        {dateService.toCustomTimeZone(
                          props.classTimeZone,
                          submission.lastChangeDate.toString()
                        )}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          backgroundColor: submission.late
                            ? dangerColor
                            : "inherit",
                        }}
                      >
                        {submission.status}
                      </TableCell>

                      <TableCell align="right">
                        {submission.score === -1 ? "-" : submission.score}
                      </TableCell>
                      <TableCell align="left">
                        <DraftSubmissionDetails
                          timezone={props.timezone}
                          drafts={submission.draftSubmissions}
                        />
                      </TableCell>
                      <TableCell>
                        <Button
                          fullWidth
                          variant="contained"
                          href={"/readassignment/" + submission.submissionId}
                          target="_blank"
                        >
                          Read
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
